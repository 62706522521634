import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

import "./src/styles/global.scss";

import * as React from "react";
import {GatsbyBrowser} from "gatsby";
import {ConsentContextWrapper} from "./src/contexts/consent.context";

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({element}) => <ConsentContextWrapper>{element}</ConsentContextWrapper>;

// Always returning "scroll to the top" result of [0, 0] to prevent FullPage from breaking scrolling effect.
export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = () => [0,0];
